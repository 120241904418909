import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from '../../../utils';

import { GLOBAL_SETTING_VALUES } from '../../../constants/common';
import errorMessages from '../../../constants/errors';

import { getGlobalSettings, updateGlobalSettings } from '../../../actions';

import withRequest from '../../HOCs/withRequest';

import { useMount } from '../../hooks';

import View from '../../Layout/View/View';

import NoMedia from '../../UI/NoMedia/NoMedia';

const GlobalSettings = ({ createCanceler, dispatch }) => {
  const [data, setData] = useState({
    settings: [],
    isFetching: false,
    isUpdating: false,
  });
  const [error, setError] = useState(null);

  const getSettings = useCallback(() => {
    setData({
      settings: [],
      isFetching: true,
      isUpdating: false,
    });

    const getGlobalSettingsRequest = createCanceler();

    dispatch(getGlobalSettings(getGlobalSettingsRequest.token))
      .then((res) => {
        setData({
          settings: res,
          isFetching: false,
          isUpdating: false,
        });
      })
      .catch((err) => {
        setError(err);

        setData({
          settings: [],
          isFetching: false,
          isUpdating: false,
        });
      });
  }, [createCanceler, dispatch]);

  useMount(() => {
    getSettings();
  });

  const handleChangeSetting = (newData) => {
    const { name, value } = JSON.parse(newData);

    setData({
      settings: data.settings.map((setting) => {
        if (setting.name !== name) return setting;

        return { ...setting, value };
      }),
      isFetching: false,
    });
  };

  const handleFormSubmit = () => {
    setData({ ...data, isUpdating: true });

    const updateGlobalSettingsRequest = createCanceler();

    dispatch(updateGlobalSettings(data.settings, updateGlobalSettingsRequest.token))
      .then(() => {
        toast('success', 'Настройки сохранены');

        setData({ ...data, isUpdating: false });
      })
      .catch((err) => {
        const message = errorMessages[err?.message] ? errorMessages[err.message] : 'Ошибка';

        toast('error', message);

        setData({ ...data, isUpdating: false });
      });
  };

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={!!error}
      isFetching={data.isFetching}
      title="Глобальные переменные"
      viewClass="global-settings"
      content={
        !data.isFetching && data.settings.length ? ( // eslint-disable-line
          <>
            <div className="global-settings__form">
              {data.settings.map((setting) => GLOBAL_SETTING_VALUES[setting.name] ? ( // eslint-disable-line
                <div key={setting._id} className="global-settings__form-row">
                  <div className="global-settings__form-label">
                    {GLOBAL_SETTING_VALUES[setting.name].title}
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      as="button"
                      className="btn btn-light"
                      disabled={data.isUpdating}
                    >
                      {GLOBAL_SETTING_VALUES[setting.name].valueTitles[GLOBAL_SETTING_VALUES[setting.name].values.indexOf(setting.value)]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {GLOBAL_SETTING_VALUES[setting.name].values.map((value, i) => (
                        <Dropdown.Item
                          as="button"
                          eventKey={JSON.stringify({
                            name: setting.name,
                            value,
                          })}
                          disabled={setting.value === value}
                          key={value}
                          onSelect={handleChangeSetting}
                        >
                          {GLOBAL_SETTING_VALUES[setting.name].valueTitles[i]}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null)}
              <div className="global-settings__form-footer">
                <Button
                  onClick={handleFormSubmit}
                  variant="success"
                  disabled={data.isUpdating}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </>
        ) : !data.isFetching ? (
          <NoMedia
            caption="Ничего не найдено"
            icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
          />
        ) : null
      }
    />
  );
};

GlobalSettings.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(GlobalSettings));
